<template>
  <div id="app">
    <layout-default>
      <router-view />
    </layout-default>
  </div>
</template>

<script>
import LayoutDefault from '@/layout/LayoutDefault'
import { mapGetters } from "vuex"

export default {
  name: 'App',
  components: {
    LayoutDefault
  },
  computed: {
    ...mapGetters({
      isStateBulb: 'TheSideBar/getIsStateBulb'
    })
  }
}
</script>

<style lang="scss">
@import '~@/assets/css/_main.scss';
</style>
