const sidebar = {
    namespaced: true,
    state: {
        isStateSideBar: false,
        isStateBulb: false
    },
    mutations: {
        SET_STATE_SIDEBAR (state, isState = false) {
            state.isStateSideBar = isState
        },
        SET_STATE_BULD (state, isState = false) {
            state.isStateBulb = isState
            if (isState) {
                localStorage.setItem('tool-save-theme-light', String(isState))
            } else {
                localStorage.removeItem('tool-save-theme-light')
            }
        }
    },
    actions: {
        setStateSideBar ({ commit, state }) {
            commit('SET_STATE_SIDEBAR', !state.isStateSideBar)
        },
        setStateBuld ({ commit, state }) {
            commit('SET_STATE_BULD', !state.isStateBulb)
            document.body.classList.toggle('light')
        }
    },
    getters: {
        getIsStateSideBar: state => state.isStateSideBar,
        getIsStateBulb: state => state.isStateBulb
    }
}

export default sidebar
