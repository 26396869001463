<template>
  <div class="page-sidebar" :class="{ 'open': isStateSideBar }">
    <router-link
      v-tippy="{ placement : 'right' }"
      content="Совпадения"
      class="button page-sidebar__button"
      :class="{ 'active': $route.name === 'find-extra' }"
      type="button"
      to="/find"
    >
      <search />
    </router-link>

    <router-link
      v-tippy="{ placement : 'right' }"
      content="Замена"
      class="button page-sidebar__button"
      :class="{ 'active': $route.name === 'replace' }"
      type="button"
      to="/replace"
    >
      <replace />
    </router-link>

    <button
      class="button button--bulb page-sidebar__button"
      :class="{ 'active': isStateBuld }"
      type="button"
      @click="changeStateBulb"
    >
      <light-bulb-on v-if="isStateBuld" />
      <light-bulb-off v-else />
    </button>
  </div>
</template>

<script>
import Search from '@/assets/svg/search'
import Replace from '@/assets/svg/replace'
import LightBulbOn from '@/assets/svg/lightbulb-on'
import LightBulbOff from '@/assets/svg/lightbulb-off'
import { mapGetters } from 'vuex'

export default {
  name: "TheSideBar",
  components: {
    Search,
    Replace,
    LightBulbOn,
    LightBulbOff
  },
  computed: {
    ...mapGetters({
      isStateSideBar: 'TheSideBar/getIsStateSideBar',
      isStateBuld: 'TheSideBar/getIsStateBulb'
    })
  },
  created () {
    const isLight = Boolean(localStorage.getItem('tool-save-theme-light'))

    if (isLight) {
      this.$store.dispatch('TheSideBar/setStateBuld', isLight)
    }
  },
  methods: {
    changeStateBulb () {
      this.$store.dispatch('TheSideBar/setStateBuld')
    }
  }
}
</script>

<style lang="scss">
.page-sidebar {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 60px;
  height: 100%;
  padding: 15px 0;
  background-color: var(--block);
  box-shadow: -3px 0 7px 3px rgba(0, 0, 0, 0.4);

  @media (max-width: 800px) {
    top: auto;
    right: 0;
    flex-direction: row;
    height: 50px;
    width: 100%;
    padding: 0 15px;
  }

  &.open {
    width: 250px;
  }

  &__toggle-wrap {
    display: flex;
    justify-content: flex-end;
    width: auto;
    transition: all 250ms;

    &.open {
      width: 100%;
    }
  }

  &__button {
    & + & {
      margin-top: 20px;

      @media (max-width: 800px) {
        margin: 0;
      }
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    width: 100%;
    height: 25px;
    background-color: transparent;
    padding: 0;
    outline: none;
    border: none;
    cursor: pointer;

    @media (max-width: 800px) {
      padding: 15px;
      height: 100%;
    }

    &:hover {
      path {
        fill: var(--default-icon);
        opacity: 0.8;
      }
    }

    &:active {
      fill: var(--default-icon);
      opacity: 0.6;
    }

    &.active path {
      fill: var(--default-icon);
      opacity: 1;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .button--bulb {
    margin-top: auto;

    &:not(.active) {
      path {
        fill: var(--text);
        opacity: 1;
      }
    }

    &.active {
      path {
        fill: var(--buld-icon);
      }
    }
  }
}
</style>
