import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import TheSideBar from '@/components/TheSideBar/_store'

export default new Vuex.Store({
    modules: {
        TheSideBar
    }
})
