import Vue from 'vue'
import VueRouter from 'vue-router'

// Tools
import FindExtra from '@/views/find-extra/_router'
import Replace from '@/views/replace/_router'

Vue.use(VueRouter)

const routes = [
    FindExtra,
    Replace,
    {
        path: '*',
        beforeEnter: beforeEnter
    }

]

function beforeEnter (to, from, next) {
    if (to.name === undefined) {
        next({ name: 'find-extra' })
    }

    next()
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
