<template>
  <div class="layout-default">
    <the-side-bar />
    <main class="layout-default__content">
      <slot />
    </main>
  </div>
</template>

<script>
import TheSideBar from "@/components/TheSideBar";
import { mapGetters } from "vuex";

export default {
  name: 'LayoutDefault',
  components: {
    TheSideBar
  },
  computed: {
    ...mapGetters({
      isOpenSideBar: 'TheSideBar/getIsStateSideBar'
    })
  },
}
</script>

<style lang="scss">
.layout-default {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-left: 60px;

  @media (max-width: 800px) {
    padding-left: 0;
  }

  &__content {
    padding: 16px 32px;
    flex-grow: 1;
    background-color: var(--background);

    @media (max-width: 800px) {
      padding-bottom: 82px;
    }
  }
}
</style>
